// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {
  antiSocialOrder,
  socialOrder,
} from '@modules/ContentEngine/components/items/profile/result/roles/ProfileRolesItem';
import {Avatar} from '@modules/Core/components/base/avatar/Avatar';
import {WrapperBadge} from '@modules/Core/components/base/badge/WrapperBadge';
import {RoleCard} from '@modules/Core/components/base/cards/RoleCard';
import {Typography} from '@modules/Core/components/base/Typography';
import {Section} from '@modules/Core/components/layout/Section';
import {logger} from '@modules/Core/util/Logger';
import {profileRolesLangProps} from '@modules/Profile/config/servicesLangProps';
import {_ProfileRoles} from '@modules/Profile/types/service.model';
import {getProfilePic, getProfileRoleIcon} from '@modules/Profile/util/profileUtil';
import {TeamResultLegend} from '@modules/Team/components/team_analysis/common/TeamResultLegend';
import {_TeamProfile} from '@modules/Team/types/team.model';
import {_TeamAnalysisResultEntry} from '@modules/Team/types/teamanalysis.model';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  result: _TeamAnalysisResultEntry<_ProfileRoles[]>;
  profileData: _ProfileRoles;
  allProfiles: Record<string, _TeamProfile>;
  rolesType: 'social' | 'antiSocial';
  transparencyAccepted: boolean;
}

export const TeamRolesItem: React.FC<_Props> = ({
  transparencyAccepted,
  allProfiles,
  result,
  rolesType,
  profileData,
}) => {
  const order = rolesType === 'antiSocial' ? antiSocialOrder : socialOrder;

  const mainRepresentatives = result?.result[0] ?? {};
  const profilesRoles: _ProfileRoles = result?.result[1] ?? {};

  if (!profilesRoles) {
    return null;
  }

  const isAntiSocial = rolesType === 'antiSocial';

  return (
    <Section>
      {Boolean(!isAntiSocial) && <TeamResultLegend />}
      <div className="flex flex-row w-full gap-3 flex-wrap">
        {order
          .filter(key => profilesRoles[key]?.value !== null)
          .map((key, index) => {
            const Icon = getProfileRoleIcon(key);
            const mainRepresentativeData = mainRepresentatives?.[key];
            const mainRepresentativeProfile = allProfiles?.[mainRepresentativeData?.profile];

            // alternative representative is first one after the main representative in profileRoles list

            const currentRoleProfiles = profilesRoles[key]?.profileValues?.sort((a, b) => b.value - a.value);

            let firstNonMainRepId;
            let alternativeRepresentativeProfile = null;

            for (let i = 0; i < currentRoleProfiles.length; i++) {
              if (currentRoleProfiles[i].profile !== mainRepresentativeData?.profile) {
                firstNonMainRepId = currentRoleProfiles[i].profile;
                break;
              }
            }

            if (firstNonMainRepId) {
              alternativeRepresentativeProfile = allProfiles[firstNonMainRepId];
            }
            // sort by .value, and get first one that is not === mainRepresentativeData

            const profileIndicator = profilesRoles[key]?.profileValue ?? 0;
            const teamAverage = profilesRoles[key]?.value ?? 0;
            const score = isAntiSocial ? teamAverage : profileIndicator;

            return (
              <div className="flex flex-col relative-col-width-5">
                <RoleCard
                  icon={Icon}
                  score={score}
                  description={
                    <div className="flex flex-col gap-2">
                      {trans(profileRolesLangProps[key].text)}
                      {Boolean(!isAntiSocial) && (
                        <>
                          <div className="flex flex-row gap-0">
                            <Typography>{trans('project_team_result.roles.main_representation')}</Typography>{' '}
                            <Typography>
                              {mainRepresentativeProfile?.fullName || '...'}
                            </Typography>
                          </div>
                          <div className="flex flex-row gap-0">
                            <Typography>{trans('project_team_result.roles.alternate_representation')}</Typography>{' '}
                            <Typography>
                              {alternativeRepresentativeProfile?.fullName || '...'}
                            </Typography>
                          </div>
                        </>
                      )}
                    </div>
                  }
                  title={trans(profileRolesLangProps[key].title)}
                  showExpander
                  avatarComponent={
                    isAntiSocial ? null : (
                      <Avatar size="lg" image={getProfilePic(mainRepresentativeProfile)} indicator="success" />
                    )
                  }
                  indicators={
                    isAntiSocial
                      ? []
                      : [
                          // profile indicator
                          {
                            value: profileIndicator,
                            color: 'var(--color-green700)',
                          },
                          // team average
                          {
                            value: teamAverage,
                            color: 'var(--color-prussian700)',
                          },
                        ]
                  }
                  renderAvatarComponent={({value, profilePicture}) => {
                    return (
                      <WrapperBadge value={value}>
                        <Avatar size="lg" image={profilePicture} indicator="success" />
                      </WrapperBadge>
                    );
                  }}
                  scores={
                    isAntiSocial
                      ? []
                      : (profilesRoles[key]?.profileValues ?? []).map((profileRole, index) => {
                          let value = profileRole?.value ?? 0;
                          // round to 2 decimal places
                          value = Math.round(value * 100) / 100;
                          return {
                            value,
                            profilePicture: getProfilePic(allProfiles[profileRole.profile]),
                          };
                        })
                  }
                  expandedContents={[
                    {
                      description: (
                        <>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: trans(profileRolesLangProps[key].description),
                            }}
                          />
                          <Typography bold>{trans('profile_result.easy_for_a_role')}</Typography>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: trans(profileRolesLangProps[key].easy),
                            }}
                          />
                          <Typography bold>{trans('profile_result.hard_for_a_role')}</Typography>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: trans(profileRolesLangProps[key].hard),
                            }}
                          />
                        </>
                      ),
                    },
                  ]}
                />
              </div>
            );
          })}
      </div>
    </Section>
  );
};
